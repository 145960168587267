<template>
  <div class="Auto">
    <AutoList />
    <div></div>
  </div>
</template>
<script>
import AutoList from "./List";

export default {
  name: "Auto",

  components: {
    AutoList,
  },

  created() {
    this.init();
  },

  data() {
    return {};
  },

  methods: {
    init() {},
  },
};
</script>
<style scoped lang="less">
.Auto {
  height: 100%;
}
</style>